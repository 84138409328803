/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 a1.glb -T 
Files: a1.glb [4.78MB] > a1-transformed.glb [208.09KB] (96%)
*/

import React, { useRef } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'

export function A1(props) {
  const { nodes, materials } = useGLTF('/a1-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <pointLight intensity={54351.413} decay={2} position={[4.076, 33.15, -1.005]} rotation={[-1.839, 0.602, 1.932]} />
      <PerspectiveCamera makeDefault={false} far={100} near={0.1} fov={22.895} position={[7.359, 32.205, 6.926]} rotation={[-0.627, 0.71, 0.441]} />
      <mesh geometry={nodes.a1.geometry} material={materials['Material.001']} position={[0, 27.246, 0]} />
      <mesh geometry={nodes.a2.geometry} material={materials['Material.002']} position={[3.625, 40.749, -3.607]} />
    </group>
  )
}

useGLTF.preload('/a1-transformed.glb')
