import {useState} from 'react';

const Overlay = () => {
 
  const[fh2, setFh2] = useState("Login Form");
  const[fbut, setFbut] = useState("Login");
  const[lpara, setLpara]= useState("none");
  const[rpara, setRpara]= useState("none");
  const[femail, setFemail]=useState("none");
  const[fheight,setFheight]=useState("30vw");

   //change login to register
  const ltor=()=>{
    setFh2("Register Form"); 
    setFbut("Register");
setFemail("block");
setLpara("block");
setRpara("none");
setFheight("30vw");    
  };

   //change register to login
  const rtol=()=>{
    setFh2("Login Form");
    setFbut("Login");
    setFemail("none");
    setRpara("block");
setLpara("none");
setFheight("24vw");
  };

  //logIn Button Click
  const[disp, setDisp] = useState("none");
  const handleLogin = () =>{
setDisp("block");
rtol();
  };

  //Register Button Click
  const handleRegister = () =>{
handleLogin();
ltor();
  };

//closer
const closer=()=>{
  setDisp("none");
}

  return (
    <>

<div className="char" style={{ top: 30, left: "41vw", color:"#562a0e"}}>
SUMMATION
      </div>

     <div className="hh4" style={{ top: 70, left: "42.5vw", color:"#c8691c"}}>
<i>Stainless Steel & Machinery</i>
      </div> 

      <div className="sl" style={{top:30, right:"7vw", display:"inline-block"}}>

        <button className="b1" onClick={handleLogin} style={{float:"left", cursor:"pointer"}} >LogIn</button>

        <button className="b2" onClick={handleRegister} style={{cursor:"pointer"}} >Register</button>
      </div>

      <div className="sbl" style={{top:"10vh", left:"1vw",fontSize: "1vw"}}>
        <p>Text</p>
      </div>

      <div className="sbr" style={{top:"10vh", right:"1vw", fontSize: "1vw"}}>
        <p>image</p>
      </div>

      <div className="sbr" style={{top:"47.5vh", right:"1vw", fontSize: "1vw"}}>
        <p>image</p>
      </div>

      <div className="ftr" style={{bottom:"3.5vh", right:"1vw", left:"1vw", fontSize: "0.75vw"}}>
        <p>Footer</p>
      </div>

      <div className="akm" id="loginFormContainer" style={{display: disp, height: fheight}}>

      <div className='closer'>
      <h2 onClick={closer} style={{cursor:"pointer"}}>x</h2>
    </div><br />

		  <form method="post" action="login.php" >          
			<h2>{fh2}</h2>

			
			  <label><i>Username:</i></label><br />
			  <input type="text" id="usernameLogin" name="username" required /><br />
			
  
			<div className='fem' style={{display: femail}}>
				<label><i>Email:</i></label><br />
				<input type="text" id="emailRegister" name="email" required /><br />
			  </div>
	  
			
			  <label><i>Password:</i></label><br />
			  <input type="password" id="password" name="password" required /><br /><br />
			
	  
			<button type="submit">{fbut}</button><br />
		  
	  
		  <i><p onClick={handleRegister} style={{display: rpara, cursor:"pointer"}}>No register account? Click here.</p></i>

      <i><p onClick={handleLogin} style={{display: lpara, cursor:"pointer"}}>Already have an account? Click here.</p></i>

		  </form>
		  </div>
   
    </>
  )
}

export default Overlay;


