import { Canvas } from '@react-three/fiber'
import { BakeShadows, OrbitControls, Stage, Sky } from '@react-three/drei'
import { A1 } from './A1'


function App() {
  return (   
    
    <Canvas frameloop="demand" shadows camera={{ position: [0, 100, 150], fov: 40, zoom: 0.8 }}>
      <Stage preset='portrait' environment="forest" intensity={0.9}>  
        <A1 />
      </Stage>
      <BakeShadows />
       <OrbitControls />
    </Canvas>
  )
}

  export default App;
